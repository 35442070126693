import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const Close = ({ onClick }) => (
  <StyledClose onClick={onClick}>
    <div className="close-wrapper">
      <motion.div className="line" style={{ rotate: -45 }} />
      <motion.div className="line" style={{ rotate: 45 }} />
    </div>
  </StyledClose>
);

const StyledClose = styled.button`
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--sp-1-8);
  padding-top: var(--sp-1);

  .close-wrapper {
    height: 32px;
    width: 32px;
    background: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    &:hover {
      background: var(--red);

      .line {
        background: var(--white);
      }
    }
  }

  .line {
    width: 13px;
    height: 1px;
    background: var(--black);
    position: absolute;
    transition: 0.3s;
  }
`;

Close.propTypes = {
  onClick: PropTypes.func,
};

export default Close;
