import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Tag from '../components/atoms/tag';
import ArticleRichText from '../components/atoms/articleRichtext';
import { media } from '../utils/media-queries';
import Close from '../components/atoms/close';
import SEO from '../components/organisms/seo';
import { useWindowSize } from '../hooks/useWindowSize';
import { ease } from '../utils/easing';
import { useClientSide } from '../hooks/useClientSide';
import { LayoutContext } from '../components/organisms/layoutProvider';
import NewsPreview from '../components/molecules/newsPreview';
import NewsTransitionOverlay from '../components/sections/news/overlay';

const Article = ({ data, pageContext }) => {
  const { transition } = useContext(LayoutContext);

  let content;

  if (pageContext.type == 'interview') {
    content = data.interviews;
  }

  if (pageContext.type == 'article') {
    content = data.articles;
  }

  const { headline, date, time, mainImage, alt, _rawContent } = content;
  const { articles } = data.news.nodes[0];
  const allinterviews = data.allinterviews.nodes;
  const currentSlug = pageContext.slug;

  const filterOutCurrentArticle = articles.filter(function (article) {

    return article.slug !== currentSlug;
  });

  const filterOutCurrentInterview = allinterviews.filter(function (interview) {

    return interview.slug !== currentSlug;
  });


  const isClient = useClientSide();
  const size = useWindowSize();

  const { scrollYProgress } = useViewportScroll();
  const height = useTransform(scrollYProgress, [0, 0.8], ['0%', '100%']);

  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const progressRef = useRef(null);
  const refTimeline = useRef(null);

  const [wrapperHeight, setWrapperHeight] = useState();

  useEffect(() => {
    // ref.current.firstElementChild.classList.add('grid');
    setWrapperHeight(
      wrapperRef.current.getBoundingClientRect().height -
      progressRef.current.getBoundingClientRect().height
    );
  }, [size]);

  useEffect(() => {
    if (isClient) gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      gsap.registerPlugin(ScrollTrigger);

      if (progressRef.current) {
        refTimeline.current = gsap.timeline({
          scrollTrigger: {
            trigger: progressRef.current,
            pin: true,
            start: 'top top',
            end: `+=${wrapperHeight}`,
          },
        });
      }

      return () => {
        refTimeline.current?.scrollTrigger?.kill();
        refTimeline.current?.kill();
        refTimeline.current?.clear();
      };
    }, 900);
  }, [isClient, wrapperHeight]);


  return (
    <>
      <SEO siteTitle={headline} />
      <NewsTransitionOverlay transition={transition} />
      <StyledArticle>
        <div className="wrapper" ref={wrapperRef}>
          <motion.div
            className="article"
            initial={{ opacity: 0 }}
            animate={{ opacity: !transition ? 1 : 0 }}
            transition={{ duration: 0.2, delay: 0.3 }}
          >
            <div className="intro">
              <figure>
                <GatsbyImage
                  image={mainImage?.asset.gatsbyImageData}
                  alt={alt}
                />
              </figure>
              <h1 className="hl">{headline}</h1>
              <div className="tags">
                <Tag text={date} />
                <Tag text={time} />
              </div>
            </div>
            <div className="content" ref={ref}>
              <ArticleRichText blocks={_rawContent} className="richtext" />
            </div>
          </motion.div>
          <div className="progress" ref={progressRef}>
            <div onClick={() => navigate(-1)} className="progessClose">
              <Close />
            </div>
            <div className="bar-wrapper">
              <div className="bar">
                <motion.div className="filled" style={{ height }} />
              </div>
            </div>
          </div>
        </div>
      </StyledArticle>
      <StyledNews
        initial={{ y: 200 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.4, ease: ease.inOutSnap }}
      >
        {pageContext.type == 'article' && filterOutCurrentArticle.map((article, i) => (
          <NewsPreview
            key={i}
            newsPage
            slug={article.slug}
            type={article.type.type}
            description={article.headline}
            logo={article.logo}
            date={article.date}
            time={article.time}
            src={article.mainImage?.asset.gatsbyImageData}
            alt={article.alt}
            // className={i === 3 ? 'landscape' : ''}
          />
        ))}

        {pageContext.type == 'interview' && filterOutCurrentInterview.map((article, i) => (
          <NewsPreview
            key={i}
            newsPage
            slug={article.slug}
            type="interview"
            description={article.headline}
            logo={article.logo}
            date={article.date}
            time={article.time}
            src={article.mainImage?.asset.gatsbyImageData}
            alt={article.alt}
            className={i === 3 ? 'landscape' : ''}
          />
        ))}
      </StyledNews>
    </>
  );
};

const StyledArticle = styled.section`
  padding-top: var(--sp-13);
  margin-bottom: 6rem;

  h1 {
    font-size: 27px;
    max-width:100rem;
    margin:auto;
  }

  .desktop {
    display: none;
  }

  .progress {
    display: none;
  }

  .progessClose {
    display:flex;
    justify-content:center;
  }

  .wrapper {
    display: flex;
    background: var(--white);
    margin-left: var(--sp-1-8);
    padding-right: var(--sp-1);
    padding-bottom: var(--sp-13);
  }

  .article {
    width:100%;
  }

  .intro {
    // height: 100vh;
    margin-top:6rem;
    margin-bottom:40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--white);

    figure {
      height: auto;
      width: 60vw;
      max-width:50rem;
      // margin: var(--sp-5);

      .gatsby-image-wrapper {
        width:100%;
        height:0;
        padding-bottom:66.66%;

        img {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          object-fit:cover;
          object-position:center;
        }

      }
    }

    .hl {
      color: var(--black);
      text-align: center;
      //max-width: 80%;
      padding-bottom: var(--sp-3);
      margin-top:2.4rem;
    }

    .tags {
      display: flex;
    }
  }

  .content {
    margin-left: var(--sp-1);

    .richtext {

      max-width:80rem;
      margin:auto;
      p,h3 {
      }
    }

    figure {
      margin-top: var(--sp-3-5);
    }

    figcaption {
      color: var(--red);
      font-size: var(--fs-small);
      padding-bottom: var(--sp-5);
      text-align: right;
    }

    .mobile {
      display: flex;
      justify-content: space-between;
    }

    .dot {
      width: 8px;
      height: 8px;
      background: var(--red);
      border-radius: 50%;
      transform: translateY(5px);
    }

    .quote {
      color: var(--red);
      position: relative;

      .statement {
        font-size: var(--fs-subhl);
        font-style: italic;
        font-weight: 300;
      }

      .sign {
        font-size: 12rem;
        font-weight: 300;
        font-style: italic;
        top: 0;
      }

      .from {
        font-size: var(--fs-small);
        padding: var(--sp-1) 0;
      }
    }

    h3 {
      font-size: 2.4rem;
      padding-top: var(--sp-5);

      &:first-child {
        padding-top:0;
        font-size:1.8rem;
        opacity:0.5;
      }
    }
  }

  @media ${media.M} {
    padding-top: var(--header-height-at-m);

    .intro {

      h1 {
        font-size: var(--fs-hl);
        margin-top:4rem !important;
      }
    }

    .desktop {
      display: block;
    }

    .wrapper {
      padding-right: var(--sidebar-width-at-m);
      margin-left: var(--sidebar-width-at-m);
    }

    .progress {
      display: block;
      margin-top: var(--header-height-at-m);
      // width: var(--sp-1-8);
      position: absolute;
      top: 0;
      right: 0;
      height: 100vh;
      border-left: 1px solid rgba(0, 0, 0, 0.1);

      @media ${media.M} {
        width: var(--sidebar-width-at-m);
      }

      .bar-wrapper {
        // width: var(--sp-1-8);
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85vh;

        .bar {
          width: 2px;
          height: 50%;
          background: var(--red-light);

          .filled {
            width: 2px;
            background: var(--red);
            top: 0;
          }
        }
      }
    }

    .intro {
      figure {
        // height: 22vw;
        width: 33vw;
        padding-right: var(--sp-3);
        padding-left: var(--sp-3);
      }
    }

    .content {
      p,
      h3 {
        // grid-column: 4 / 10;
      }

      .mobile {
        display: none;
      }

      figure {
        grid-column: 3 / 11;
        margin-top: var(--sp-5);
      }

      figcaption {
        grid-column: 4 / 7;
        text-align: left;
      }

      .dot {
        grid-column: 3 / 4;
      }

      .quote {
        grid-column: 1 / 11;

        .statement {
          font-size: var(--fs-subhl);
          font-style: italic;
          font-weight: 300;
        }

        .sign {
          font-size: 12rem;
          font-weight: 300;
          font-style: italic;
          position: absolute;
          top: 0;
          height: auto;
          transform: translate3d(
            calc(var(--sp-8) * -1),
            calc(var(--sp-1) * -1),
            0
          );
        }
      }

      h3 {
        font-size: var(--fs-subhl);
        padding-top: var(--sp-5);
      }

      a {
        text-decoration:underline;
        word-break: break-all;
        transition:all 350ms ease-out;

        &:hover {
          opacity:0.5;
        }
      }
    }
  }
`;

const StyledNews = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: var(--sp-13);

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);
    margin-right: calc(var(--sp-1-8) - var(--sp-3));
  }
`;

export const query = graphql`
  query($slug: String!) {
    articles: sanityArticle(slug: { eq: $slug }) {
      headline
      type {
        type
      }
      slug
      time
      date
      alt
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      _rawContent
    }
    interviews: sanityInterview(slug: { eq: $slug }) {
      headline

      slug
      time
      date
      alt
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      _rawContent
    }
    news: allSanityNews {
      nodes {
        title
        articles {
          headline
          type {
            type
          }
          slug
          logo
          time
          date
          alt
          mainImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
    allinterviews: allSanityInterview {
      nodes {
        headline
        slug
        logo
        time
        date
        alt
        mainImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

Article.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default Article;
