/* eslint-disable react/prop-types */
import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';

const sanityConfig = { projectId: 'qqdha2cz', dataset: 'production' };

const ArticleRichText = ({ blocks, className }) => {
  const serializers = {
    types: {
      // eslint-disable-next-line react/display-name
      mainImage: ({ node }) => {
        const imageData = getGatsbyImageData(
          node.image.asset._ref,
          { maxWidth: 3600 },
          sanityConfig
        );

        return (
          <>
            <figure className="grid">
              <GatsbyImage image={imageData} alt={node.alt} />
            </figure>
            <span className="dot desktop" />
            <figcaption className="desktop">{node.caption}</figcaption>
            <div className="mobile">
              <div className="dot" />
              <figcaption>{node.caption}</figcaption>
            </div>
          </>
        );
      },
      // eslint-disable-next-line react/display-name
      quote: ({ node }) => (
        <div className="quote">
          <span className="sign">"</span>
          <p className="statement">{node.statement}</p>
          <p className="from">{node.from}</p>
        </div>
      ),
    },
    marks: {
      /* eslint-disable-next-line */
      link: ({ mark, children }) => {
        /* eslint-disable-next-line */
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
  };

  return (
    <PortableText
      className={className}
      blocks={blocks}
      serializers={serializers}
    />
  );
};

ArticleRichText.propTypes = {
  blocks: PropTypes.array,
  className: PropTypes.string,
};

export default ArticleRichText;
