import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ease } from '../../../utils/easing';
import { media } from '../../../utils/media-queries';

const NewsTransitionOverlay = ({ transition }) => (
  <StyledOverlay
    initial={{ y: '100%' }}
    animate={{ y: transition ? 0 : '100%' }}
    transition={{ duration: 0.5, ease: ease.out }}
  />
);

const StyledOverlay = styled(motion.div)`
  position: fixed;
  pointer-events: none;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: calc(100% - var(--sp-1-8));
  height: calc(100% - var(--sp-13));
  background: var(--white);
  will-change:transform;

  @media ${media.M} {
    width: calc(100% - var(--sidebar-width-at-m));
    height: calc(100% - var(--sidebar-width-at-m));
  }
`;

NewsTransitionOverlay.propTypes = {
  transition: PropTypes.bool,
};

export default NewsTransitionOverlay;
